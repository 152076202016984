import { parse } from "marked";

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const stripPTags = s => {
  if (!s) return s;

  return s.trim().replace(/^\u003cp\u003e/, '').replace(/\u003c\/p\u003e$/, ''); // strip <p> tag at the beginning and end
};

export const randomKey = () => {
  // usually 11 characters, only lower-case letters and numbers
  return Math.random().toString(36).slice(2);
};

export const uniqId = () => `id-${randomKey()}`;

export const pathnameWithoutLangPrefix = (pathname) => {
  let parts = pathname.split('/').filter(Boolean);
  parts = parts.length > 0 && ['en', 'ja'].includes(parts[0])
    ? parts.slice(1)
    : parts;
  return parts.length > 0 ? ['', ...parts].join('/') : '/';
}

// Checks if the href is either empty, null, undefined, or just a hash ('#')
export const blankHref = href => !href || href === '#';

export const removeTrailingSlash = url => url.replace(/\/+$/, '');
export const removeLeadingSlash = url => url.replace(/^\/+/, '');

export const maskEmail = email => {
  if (!email) return '';

  const splitted = email.split('@');
  const first = splitted[0];
  let masked;
  if (first.length <= 1) {
    masked = '•••••';
  } else if (first.length > 3) {
    masked = '•••••' + first.slice(first.length - 3);
  } else {
    masked = '•••••' + first[first.length - 1];
  }
  return [masked].concat(splitted.slice(1)).join('@');
};

export const makeSessionId = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const NAME_PATTERN = /^[^\s\d]+(\s?[^\s\d]+)*$/;

export const isValidName = name => {
  if (name) {
    return NAME_PATTERN.test(name);
  }
  // NOTE: Return true as middlename can be empty
  return true;
};

export const invalidNameError = name => {
  if (isValidName(name)) return '';

  if (/[ ]{2,}/.test(name)) {
    return 'space_error';
  }
  return 'invalid_name_error';
}

export const formatUnit = (quantity, unit) => {
  if (quantity === 1) {
    return `${quantity} ${unit}`;
  }
  return `${quantity} ${unit}s`;
}

export function isUrl(string) {
  try {
    new URL(string);
    return true;
  } catch {
    return false;
  }
}

export function stripHtml(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}

export function optionalNumbering(num, show) {
  return show ? `#${num}` : '';
}

export function pluralize(count, singular, _plural = null) {
  const plural = _plural || (singular + 's');
  return Math.abs(count) <= 1 ? singular : plural;
}

export function countUnit(count, singular, plural = null) {
  return `${count} ${pluralize(count, singular, plural)}`
}

export function parseMarkdown(md) {
  return parse(md ?? '');
}

export function truncateMatchingSubstring(original, term, maxLength) {
  if (original.length < maxLength) return original

  const indexOfFirst = original.indexOf(term)
  const numCharsToPreserve = Math.round((maxLength - term.length) / 2)

  let leftBoundary = indexOfFirst - numCharsToPreserve
  let rightBoundary = indexOfFirst + numCharsToPreserve

  if (leftBoundary < 0 || rightBoundary > original.length - 1) {
    if (leftBoundary < 0) {
      rightBoundary = rightBoundary - leftBoundary
      leftBoundary = 0
    } else {
      leftBoundary = leftBoundary - rightBoundary - (original.length - 1)
      rightBoundary = original.length - 1
    }
  }

  return "..." + original.substring(leftBoundary, rightBoundary) + '...'
}